<template>
  <div class="edit_shop">
    <van-nav-bar
      title="装修首页"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-cell center is-link>
        <template #title>
          <div class="check_color_title" @click="CheckColorTishi">
            <div>背景色</div>
            <van-icon name="question" />
          </div>
        </template>
        <template #default>
          <div class="check_color" @click="showCheckColor(1)">
            <div class="icon_block" :style="'background: '+checkedColor+';'"></div>
            <div>选择颜色</div>
          </div>
        </template>
      </van-cell>
      <!-- <van-cell center class="lunbotu">
        <template #title>
          修改轮播图<span style="color: #ff0000;">(建议上传680*260比例的图片)</span>
        </template>
        <template #label>
          <div class="lunbotu_list">
            <van-uploader preview-size="100px" v-model="fileList" :after-read="uploadImg" :max-count="9" :max-size="200 * 1024" @oversize="onOversize" @delete="delImg" accept="image/*" />
          </div>
        </template>
      </van-cell> -->
      <van-cell center class="category">
        <template #title>
          <div style="padding: 0 16px;">修改分类图标<span style="color: #ff0000;">(建议上传60*60比例的图片)</span></div>
        </template>
        <template #label>
          <div class="category_list">
            <van-swipe class="my-swipe" indicator-color="#c8241a" :width="screenWidth">
              <van-swipe-item v-for="(item,index) in category" :key="index">
                <van-grid :column-num="6">
                  <van-grid-item v-for="(v,k) in item" :key="k">
                    <div>
                      <van-uploader preview-size="50px" v-model="fileList2[index][k]" :name="index+'_'+k" :after-read="uploadImg2" :max-count="1" :max-size="5 * 1024" @oversize="onOversize2" @delete="delImg2" />
                    </div>
                    <div class="van-ellipsis">{{v.name}}</div>
                  </van-grid-item>
                </van-grid>
              </van-swipe-item>
            </van-swipe>
          </div>
        </template>
      </van-cell>
      <!-- <van-cell center is-link>
        <template #title>
          <div class="check_color_title" @click="CheckColorTishi2">
            <div>云店须知标题背景色</div>
            <van-icon name="question" />
          </div>
        </template>
        <template #default>
          <div class="check_color" @click="showCheckColor2">
            <div class="icon_block" :style="'background: '+yundiantitle+';'"></div>
            <div>选择颜色</div>
          </div>
        </template>
      </van-cell> -->
      <van-cell center class="lunbotu">
        <template #title>
          修改云店须知下的图标<span style="color: #ff0000;">(建议上传60*60比例的图片)</span>
        </template>
        <template #label>
          <div class="lunbotu_list">
            <van-grid :column-num="4">
              <van-grid-item v-for="(item, index) in xuzhi" :key="index">
                <div>
                  <van-uploader preview-size="50px" v-model="fileList3[index]" :after-read="uploadImg3" :max-count="1" :max-size="5 * 1024" :name="index" @oversize="onOversize3" @delete="delImg3" />
                </div>
                <div>{{item.title}}</div>
              </van-grid-item>
            </van-grid>
          </div>
        </template>
      </van-cell>
      <!-- <van-cell center is-link>
        <template #title>
          <div class="check_color_title" @click="CheckColorTishi3">
            <div>每日上新标题背景色</div>
            <van-icon name="question" />
          </div>
        </template>
        <template #default>
          <div class="check_color" @click="showCheckColor3">
            <div class="icon_block" :style="'background: '+shangxintitle+';'"></div>
            <div>选择颜色</div>
          </div>
        </template>
      </van-cell> -->
    </div>

    <div class="btn_list">
      <van-button type="danger" block round @click="saveBtn">保存</van-button>
    </div>

    <van-dialog
      v-model="ChcekColorshow"
      title="选择颜色"
      show-cancel-button
      @confirm="confirmColor"
      @cancel="cancelColor"
    >
      <div class="check_color_main" id="checkColorMain"></div>
    </van-dialog> 
    <van-dialog
      v-model="Editshow"
      title="修改位置"
    >
      <div class="edit_img">
        <van-image
          v-for="(item, index) in editimg"
          :key="index"
          :src="item"
          lazy-load
          fit="contain"
          @click="yulan(index)"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </div>
    </van-dialog> 
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
import ColorPicker from 'simple-color-picker';
import { Toast } from 'vant';
export default {
  name: "EditShop",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      ChcekColorshow: false,
      Editshow: false, // 修改位置提示
      Pickercolor: {},
      checkedColor: '#c8241a',
      fileList: [],
      lunbo: [], // 轮播图列表
      fileList2: [], // 分类图标展示
      fileList3: [], // 分类图标展示
      categoryImg: [], // 分类图标
      xuzhi: [],
      yundiantitle: '#FE4E51',
      shangxintitle: '#c8241a',
      colortype: 1, // 1 背景色，2 云店须知背景色，3 每日上新背景色
      editimg: 'https://img.midbest.cn/uploads/shop/head_back.png',
      category: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log("ddddd");
      this.getcategory();
      this.getbanner();
      this.getxuzhi();
    },
    // 获取banner 
    getbanner() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/home/getBanner")
        .then(res => {
          if (res.data.code == 100000) {
            that.lunbo = res.data.data['banner'];
            that.checkedColor = res.data.data['background'];
            that.lunbo.forEach(item => {
              that.fileList.push({
                  url: item
                })
            })
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取分类信息
    getcategory() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/home/get_onelist")
        .then(res => {
          if (res.data.code == 100000) {
            that.category = res.data.data;
            that.category.forEach(item => {
              const arr = [];
              item.forEach(v => {
                arr.push([{
                  url: v.thumb
                }]);
              })
              that.fileList2.push(arr);
            })
            console.log(that.fileList2, "that.fileList2");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取云店须知列表
    getxuzhi() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/home/get_xuzhi")
        .then(res => {
          if (res.data.code == 100000) {
            that.xuzhi = res.data.data;
            that.xuzhi.forEach(item => {
              that.fileList3.push([{
                url: item.thumb
              }]);
            })
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    showCheckColor(typecolor = 1) {
      this.colortype = typecolor;
      console.log(this.colortype);
      this.ChcekColorshow = true;
      let color = '';
      color = this.checkedColor;
      if (this.colortype == 2) {
        color = this.yundiantitle;
      }
      if (this.colortype == 3) {
        color = this.shangxintitle;
      }
      setTimeout(() => {
        this.Pickercolor = new ColorPicker({
          color: color,
          width: 200,
          height: 200
        })
        this.Pickercolor.appendTo("#checkColorMain");
      }, 500);
    },
    confirmColor() {
      // 轮播图背景色
      if (this.colortype == 1) {
        this.checkedColor = this.Pickercolor.getHexString();
      }
      // 云店须知背景色
      if (this.colortype == 2) {
        this.yundiantitle = this.Pickercolor.getHexString();
      }
      // 每日上新背景色
      if (this.colortype == 3) {
        this.shangxintitle = this.Pickercolor.getHexString();
      }
      console.log(this.colortype, this.Pickercolor.getHexString(), "dsdfsdfsdf");
      this.cancelColor();
    },
    // 选择颜色弹框关闭
    cancelColor() {
      this.Pickercolor.remove();
    },
    // 展示提示内容
    CheckColorTishi() {
      this.editimg = ['https://img.midbest.cn/uploads/shop/head_back.png','https://img.midbest.cn/uploads/shop/ydxz.png','https://img.midbest.cn/uploads/shop/mrsx.png'];
      this.Editshow = true;
    },
    // 云店须知背景色
    CheckColorTishi2() {
      // this.editimg = 'https://img.midbest.cn/uploads/shop/ydxz.png';
      this.Editshow = true;
    },
    // 每日上新背景色
    CheckColorTishi3() {
      // this.editimg = 'https://img.midbest.cn/uploads/shop/mrsx.png';
      this.Editshow = true;
      console.log(this.colortype);
    },
    // 云店须知背景色
    showCheckColor2() {
      this.showCheckColor(2);
    },
    // 每日上新背景色
    showCheckColor3() {
      this.showCheckColor(3);
    },
    // 预览
    yulan(i) {
      ImagePreview({
        images: this.editimg,
        startPosition: i
      });
    },
    // 上传图片超出限制
    onOversize(file) {
      console.log(file);
      this.$toast("图片大小不能超过 200K");
    },
    
    // 上传图片
    uploadImg(file,detail) {
      console.log('上传',file,detail, this.fileList);
      let that = null;
      that = this;
      let shopid = 0;
      shopid = localStorage.getItem("SHOP_MID") ? parseInt(localStorage.getItem("SHOP_MID")) : 0;

      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/shop/zhuangxiu/'+shopid);
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.lunbo.push(response.data.img_url);
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 删除上传图片
    delImg(file,detail) {
      console.log('删除',file,detail);
      let index = 0;
      index = parseInt(detail.name);
      this.lunbo.splice(index, 1);
    },

    // 上传图片超出限制
    onOversize2(file) {
      console.log(file);
      this.$toast("图片大小不能超过 5K");
    },
    // 上传图片
    uploadImg2(file,detail) {
      let that = null;
      that = this;
      console.log('上传',file,detail);
      let shopid = 0;
      shopid = localStorage.getItem("SHOP_MID") ? parseInt(localStorage.getItem("SHOP_MID")) : 0;
      let arr = null;
      arr = detail.name.split('_');

      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/shop/zhuangxiu/'+shopid);
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.category[arr[0]][arr[1]].thumb = response.data.img_url;
          that.fileList2[arr[0]][arr[1]] = [{
            url: response.data.img_url
          }]
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 删除上传图片
    delImg2(file,detail) {
      console.log('删除',file,detail);
      let arr = null;
      arr = detail.name.split('_');
      this.category[arr[0]][arr[1]].thumb = '';
    },

    // 上传图片超出限制
    onOversize3(file) {
      console.log(file);
      this.$toast("图片大小不能超过 5K");
    },
    // 上传图片
    uploadImg3(file,detail) {
      let that = null;
      that = this;
      let shopid = 0;
      shopid = localStorage.getItem("SHOP_MID") ? parseInt(localStorage.getItem("SHOP_MID")) : 0;
      let index = 0;
      index = parseInt(detail.name);
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/shop/zhuangxiu/'+shopid);
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.xuzhi[index].thumb = response.data.img_url;
          that.fileList3[index] = [{
            url: response.data.img_url
          }];
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 删除上传图片
    delImg3(file,detail) {
      console.log('删除',file,detail);
      let index = 0;
      index = parseInt(detail.name);
      this.xuzhi[index].thumb = '';
    },

    // 保存修改
    saveBtn() {
      console.log(this.category);
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/set_zhuangxiu",
          that.$qs.stringify({
            background: that.checkedColor,
            category: that.category,
            xuzhi: that.xuzhi,
            lunbo: that.lunbo
          })
        )
        .then(res => {
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : '设置成功');
          }else {
            that.$toast(res.data.msg ? res.data.msg : '设置失败');
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
  }
}
</script>
<style lang="less">
.edit_shop {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
  .main {
    .van-cell__title {
      flex: 1;
      text-align: left;
      align-items: center;
      overflow: hidden;
      .check_color_title {
        display: flex;
        align-items: center;
        .van-icon {
          margin: 0 10px;
        }
      }
    }
    .check_color {
      display: flex;
      align-items: center;
    }
    .icon_block{
      height: 10px;
      width: 10px;
      overflow: hidden;
      margin-right: 10px;
    }
    .lunbotu {
      .van-cell__title {
        flex: 1;
      }
      .van-uploader__preview,
      .van-uploader__upload {
        margin: 0 10px 10px 0;
      }
    }
  }
  .category {
    padding: 10px 0 0;
    overflow: hidden;
    .van-swipe__track {
      margin-bottom: 10px;
    }
    .van-swipe {
      padding-bottom: 10px;
    }
    .van-grid {
      margin-bottom: 10px;
    }
    .van-swipe__indicator {
      background-color: #bbb;
    }
  }
  .check_color_main {
    height: 200px;
    padding: 0 10px;
    text-align: center;
    margin: 10px auto;
    overflow: hidden;
    .Scp {
      margin: 0 auto;
    }
  }

  .edit_img {
    display: flex;
    overflow: hidden;
    .van-image {
      margin-right: 10px;
      &:first-of-type {
        margin-left: 10px;
      }
    }
  }

  .btn_list {
    margin: 20px;
    overflow: hidden;
  }
  .van-grid-item__content {
    padding: 10px 8px;
  }
}
</style>